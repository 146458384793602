@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

body {
    font-family: "Roboto", sans-serif !important;
    background-color: #fafafa;
    color: #000000;
}
body input,
body textarea {
    font-family: "Roboto", sans-serif;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
*:focus-visible,
*:focus {
    outline: none;
}
*::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
    height: 8px;
}
*::-webkit-scrollbar-thumb {
    background-color: #22222280;
    border-radius: 4px;
}
.MuiTextField-root
    input:not(:focus):not(:hover):not(.MuiSelect-nativeInput):not(
        [type="color"]
    ):not([type="file"]),
.MuiInputBase-multiline:not(:focus-within):not(:hover) textarea,
.MuiSelect-select:not(:hover):not(:focus),
.MuiAutocomplete-root:not(:hover):not(:focus-within)
    .MuiAutocomplete-inputRoot
    > div:nth-of-type(1):not(:has(span)) {
    opacity: 0.45;
}
.MuiSelect-select.Mui-disabled {
    opacity: 0 !important;
    pointer-events: none;
}
.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.2;
    box-shadow: none;
}
.absoluteBlock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.customSelectList {
    border-radius: 20px !important;
}
.customSelectList .MuiMenuItem-root[aria-selected="true"] {
    color: #a34602;
}
.customSelectList .MuiMenuItem-root {
    font-size: 15px;
    padding: 8px 16px;
    height: 48px;
    background: white;
    color: rgba(0, 0, 0, 0.87);
    text-transform: capitalize;
}
.columnDirection {
    flex-direction: column;
}
.flexBox {
    display: flex;
}
.alignCenter {
    align-items: center;
}
.alignEnd {
    align-items: flex-end;
}
.justifyCenter {
    justify-content: center;
}
.justifyBetween {
    justify-content: space-between;
}
.justifyAround {
    justify-content: space-around;
}
.justifyEnd {
    justify-content: flex-end;
}
.flex1 {
    flex: 1;
}
body .fontLabel {
    font-size: 15px;
    color: #4b4b4b;
    margin-bottom: 10px;
}
.fontM {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.6;
    color: #4b4b4b;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.sliderVal {
    line-height: 1.2;
    font-size: 12px;
    padding: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #e86100b2;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-top: -32px;
}
.transparent_border {
    border: 1px solid transparent !important;
}
.transparent_bg {
    background: transparent !important;
}
[contenteditable] {
    -webkit-user-modify: read-write-plaintext-only;
    -moz-user-modify: read-write-plaintext-only;
}
.w-full {
    width: 100%;
}
.textCenter {
    text-align: center;
}
.mediumFont {
    font-size: 14px;
    font-weight: 500;
    color: #4b4b4b;
}

.sample-drag-bar {
    flex-shrink: 0;
    width: 5px;
    background-color: #d1d5db;
    cursor: col-resize;
    transition: background-color 0.15s 0.15s ease-in-out;
}

.sample-drag-bar.sample-drag-bar--dragging,
.sample-drag-bar:hover {
    background-color: #63b3ed;
}

.sample-drag-bar.sample-drag-bar--horizontal {
    height: 5px;
    width: 100%;
    cursor: row-resize;
}

.focusableBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid transparent;
    transition: border 0.3s, opacity 0.3s;
    opacity: 0;
}
.focusableBlock:hover {
    opacity: 0.5;
    border-color: #1890ff;
}
.focusableBlock.active {
    opacity: 1;
    border-color: #1890ff;
}
.focusableBlock.active .editBlock {
    display: flex;
}

.focusableBlock .editBlock {
    z-index: 3;
    position: absolute;
    background-color: #1890ff;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    padding: 0 6px;
    border-radius: 15px 15px 0 0;
    align-items: center;
    justify-content: center;
    display: none;
}
.focusableBlock .editBlock span {
    line-height: 0;
}
.focusableBlock .editBlock svg {
    margin: 8px;
    cursor: pointer;
}
.focusableBlock .editBlock span svg.sortIcon {
    height: 13px;
    width: 13px;
    cursor: grab;
}
.focusableBlock .editBlock span svg.sortIcon:focus {
    cursor: grabbing;
}

.focusableBlock .editBlock svg.deleteIcon {
    height: 17px;
    width: 17px;
}
.focusableBlock .editBlock svg.editIcon {
    height: 14px;
    width: 14px;
}
.focusableBlock .editBlock svg path {
    fill: #fff;
}

.DropAreaWrap {
    position: absolute;
    left: 50%;
    top: 50%;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.DropAreaWrap .DropAreaIcon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    border-radius: 25px;
    border: 2px dashed #d3d3d3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    transition: all 0.3s;
}
.DropAreaWrap .DropAreaIcon svg path {
    transition: all 0.3s;
}
.DropAreaWrap .DropAreaIcon svg {
    width: 10px;
    height: 10px;
}

.DropAreaWrap .DropAreaTitle {
    font-size: 10px;
    font-weight: 700;
    color: #b4b3b3;
    font-style: italic;
    color: #d3d3d3;
    text-align: center;
    transition: all 0.3s;
}

/* All elements using this class will hide on printing */
@media print {
    .hide_on_print {
        display: none !important;
    }
    .remove_border {
        border: 0 !important;
        box-shadow: none !important;
    }
    .transparent_border {
        border: 1px solid transparent !important;
    }
    html,
    body {
        height: initial !important;
        overflow: hidden !important;
    }
    .PageEditorWrap {
        margin: 0 !important;
    }
}
